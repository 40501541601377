export class Footer {
  constructor() {
    this.footerEl = document.querySelector('.Footer');
    this.dropDownBtns = document.querySelectorAll('.Footer-dropDownBtn');
    this.colBtns = document.querySelectorAll('.Footer-colBtn');

    this.colBtns.forEach((btn) => {
      const contentEl = document.getElementById(`${btn.dataset.links}`);

      if (contentEl) {
        btn.addEventListener('click', () => {
          contentEl.classList.toggle('is-open');
          btn.classList.toggle('is-open');
        });
      }
    });

    this.dropDownBtns.forEach((btn) => {
      const contentEl = document.getElementById(`${btn.dataset.content}`);

      if (contentEl) {
        btn.addEventListener('click', () => {
          contentEl.classList.toggle('is-open');
          btn.classList.toggle('is-open');
        });
      }
    });
  }
}
